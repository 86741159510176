import InfiniteLoading from 'vue-infinite-loading'
import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks'
import VueAutonumeric from 'vue-autonumeric'
import Moment from 'moment'
import UniqBy from 'lodash.uniqby'

import AxiosRequest from "../utils/axios-request"
# FILTERS
import toCurrency from '../filters/to_currency'

# Local imports
import AddAuction from '../methods/auctions/add_auction'
import Favourite_lot from '../methods/auctions/favourite_lot'

Vue.use TurbolinksAdapter

document.addEventListener 'turbolinks:load', ->
  el = document.getElementById 'show_auction'
  return false unless el?

  {id, title, user} = document.getElementById('initial').dataset
  data = ->
    keywords = ''
    categories = []
    subcategories = []
    preservations = []
    {
      filter_interactions: 1
      subcategories: []
      subcategory_names: []
      page: 0
      lots: []
      users: []
      customers: []
      bid: 0
      live: undefined
      index: undefined
      auction_public: undefined
      last_lot: undefined
      admin: undefined
      search_filters: {
        keywords
        categories
        subcategories
        preservations
      }
    }
  new Vue {
    el
    data
    mounted: ->
      @fetchLots()
      @getSubcategories(@search_filters.categories, false)
    methods: {
      AddAuction
      Favourite_lot
      fetchLots: (mode = 'get', $state) ->
        {
          keywords
          categories
          subcategories
          preservations
        } = @search_filters
        q = {
          title_cont_any: keywords.split(' ') if keywords
          category_id_in: categories if categories.length
          subcategory_id_in: subcategories if subcategories.length
          preservation_id_in: preservations if preservations.length
        }
        request = $.get "/auctions/lots/#{id}.json", { q: q, page: @page }
        if mode == 'infinite'
          request.then (data) =>
            @live = data.live
            @auction_public = data.auction_public
            @admin = data.admin
            @customers = data.customers
            new_lots = @lots
            new_lots.push(data.lots...)
            @lots = UniqBy(new_lots, 'idlot')
            if data.lots.length < 15
              $state.complete()
              @page = 0
            else
              @page += 1
              $state.loaded()
        if mode == 'get'
          lots = []
          request.then (data) =>
            @live = data.live
            @auction_public = data.auction_public
            @admin = data.admin
            @customers = data.customers
            @page = 1
            @lots = data.lots
      filtersChanged: ->
        @filter_interactions++
        @page = 1
        @fetchLots()
      assignProperty: (property, value) ->
        @search_filters[property] = value
      clearProperty: (property, reload = true) ->
        @search_filters[property] = '';
        @fetchLots() if reload
      find_customer: (customer) -> @customers.some (el) => el.customer_id == customer
      infiniteHandler: ($state) ->
        @fetchLots('infinite', $state)
      resetState: ->
        history.pushState({}, 'Ver Todos Nuestros Lotes', document.location.origin + document.location.pathname)
        Object.assign @$data, data()
      getSubcategories: (categories, launch_control = true) ->
        @search_filters.subcategories = [] if launch_control
        subcats = []
        if categories.length
          for category in categories
            AxiosRequest("/categories/#{category}.json?auction_id=#{id}").then ({ data }) =>
              console.log 'data', data
              subcats.push(data...)
              @subcategories = UniqBy(subcats, 'id')
        else
          @subcategories = []
    }
    watch: {
      'search_filters.categories': (categories) ->
        @filtersChanged()
        @getSubcategories(categories)
      'search_filters.subcategories': -> @filtersChanged()
      'search_filters.subcategory_types': -> @filtersChanged()
      'search_filters.preservations': -> @filtersChanged()
      'search_filters.keywords': -> @filtersChanged()
    }
    components: {
      VueAutonumeric
      InfiniteLoading
    }
    computed: {
      active_filters: ->
        f = @search_filters
        f.keywords || f.preservations.length || f.categories.length
    }
    filters: {toCurrency}
  }
